import { log, viewport, debounce } from "./_utils.js";

const isMobile = window.innerWidth < 767 ? true : false; // small video src on mobile

const video_wrapper = document.querySelectorAll(".video_wrapper");
const videos = document.querySelectorAll("video");
let videoState = {};

function unmute(video, button) {
  muteAll();
  video.muted = false;
  button.style.display = "none";
}

function muteAll () {
  videos.forEach( (video) => {
    video.muted = true;
    video.nextElementSibling.style.display = "block";
  })
}

/**
 * set Video src
 * @param video
 */
videos.forEach(function (video,i) {
  const isWebmSupport = video.canPlayType("video/webm");
  const button = video.nextElementSibling;

  button.addEventListener("click", () => unmute(video, button));

  if (isWebmSupport && !isMobile) {
    video.setAttribute("src", videoSrc[i].desktopWebm);
    return;
  }
  if (isWebmSupport && isMobile) {
    video.setAttribute("src", videoSrc[i].mobileWebm);
    // console.log(isMobile);
    return;
  }
  if (!isWebmSupport && !isMobile) {
    video.setAttribute("src", videoSrc[i].desktopMp4);
    return;
  }
  if (!isWebmSupport && isMobile) {
    video.setAttribute("src", videoSrc[i].mobileMp4);
    return;
  }
});

/**
 * createObserver
 */
const options = {
  root: null,
  rootMargin: "0px",
  threshold: 1,
};
function createObserver(video_wrapper, state) {
  let observer;
  observer = new IntersectionObserver((entries) => {
    entries.forEach((isEntry) => {
      const videos = isEntry.target.querySelectorAll("video");
      const isID = isEntry.target.dataset.id;
      handleVideo(isEntry, videos, isID, state);
    });
  }, options);
  observer.observe(video_wrapper);
}

/**
 * handle Video
 */
function handleVideo(isEntry, videos, isID, state) {
  if (isEntry.intersectionRatio < 1) {
    if (videoState[isID] === true) {
      videos.forEach((video) => video.pause());
      videoState[isID] = false;
      // console.log(" - "  + videoState[isID] + ' ' + videos[0].dataset.src );
    }
    return;
  } else {
    if (videoState[isID] === false || videoState[isID] === undefined) {
      videos.forEach((video) => {
        video.play();
        // video.muted = true;
      });

      videoState[isID] = true;
      // console.log(" + "  + videoState[isID] + ' ' + videos[0].dataset.src );
    }
  }
}

/**
 * init
 */
function init(state) {
  if (!!window.IntersectionObserver) {
    video_wrapper.forEach((video_wrapper) =>
      createObserver(video_wrapper, state)
    );
  } else {
    videos.forEach((video) => video.play());
  }
}

export default (state) => {
  return init(state);
};
