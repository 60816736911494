/**
 * viewport
 */

export function viewport(parm) {
    var e = {};
    e.width = window.innerWidth;
    e.height = window.innerHeight;
  
    return e;
  }
  
  /**
   * throttle
   * run on start, than wait "delay" till next run
   *
   * usage:
   * window.addEventListener('resize', throttle(function(e){console.log(e)}, 100));
   */
  
  export function throttle(callback, limit) {
    var wait = false;
    return function () {
      if (!wait) {
        callback.apply(null, arguments);
        wait = true;
        setTimeout(function () {
          wait = false;
        }, limit);
      }
    };
  }
  
  /**
   * debounce
   * first wait for "delay" and than run
   *
   *
   * usage:
   * var myEfficientFn = debounce(function() { myFunc() }, 250);
   * window.addEventListener('resize', myEfficientFn);
   */
  
  export function debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }
  
  /**
   * preload images one after another
   * images is an array with image metadata
   * preload(images);
   */
  
  export function preload(imageArray) {
    var imageArray = imageArray;
    var index = 0;
  
    function iterating(imageArray, index) {
      if (imageArray && imageArray.length > index) {
        var img = new Image();
        img.src = imageArray[index];
        img.onload = function () {
          iterating(imageArray, index + 1);
          // console.log(imageArray[index]);
          index++;
        };
      }
    }
    iterating(imageArray, index);
  }
  