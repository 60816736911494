/*** =====================================
 * Shuffle
 * https://vestride.github.io/Shuffle/
 * ==================================== ***/

var Shuffle = window.Shuffle;

class ProjectsShuffle {
  constructor(element) {
    this.element = element;
    this.shuffle = new Shuffle(element, {
      itemSelector: ".shuffle-item",
      sizer: element.querySelector(".shuffle-sizer-element"),
    });

    // Log events.
    this.addShuffleEventListeners();
    this._activeFilters = [];
    this.addFilterButtons();
  }

  /**
   * Shuffle uses the CustomEvent constructor to dispatch events. You can listen
   * for them like you normally would (with jQuery for example).
   */
  addShuffleEventListeners() {
    // this.shuffle.on(Shuffle.EventType.LAYOUT, (data) => {
    //   console.log("layout. data:", data);
    // });
    // this.shuffle.on(Shuffle.EventType.REMOVED, (data) => {
    //   console.log("removed. data:", data);
    // });
  }

  addFilterButtons() {
    const options = document.querySelector(".filter-options");

    if (!options) {
      return;
    }

    const filterButtons = Array.from(options.children);
    const onClick = this._handleFilterClick.bind(this);
    filterButtons.forEach((button) => {
      button.addEventListener("click", onClick, false);
    });
  }

  _handleFilterClick(evt) {
    const btn = evt.currentTarget;
    const isActive = btn.classList.contains("active");
    const btnGroup = btn.getAttribute("data-group");

    this._removeActiveClassFromChildren(btn.parentNode);

    let filterGroup;
    if (isActive) {
      btn.classList.remove("active");
      filterGroup = Shuffle.ALL_ITEMS;
    } else {
      btn.classList.add("active");
      filterGroup = btnGroup;
    }

    this.shuffle.filter(filterGroup);
  }

  _removeActiveClassFromChildren(parent) {
    const { children } = parent;
    for (let i = children.length - 1; i >= 0; i--) {
      children[i].classList.remove("active");
    }
  }
}



document.addEventListener("DOMContentLoaded", () => {
  if ( !document.getElementById("shuffle") ) {
    return
  }
  window.projectsShuffle = new ProjectsShuffle(
    document.getElementById("shuffle")
  );
});

