(function($) {
'use strict';

    $(document).ready(function(){

    

    // bind sort button click
    // $('.filter-options').on( 'click', '.filter', function() {
    //   var sortValue = $(this).attr('data-sort-value');
    //   $grid.isotope({ sortBy: sortValue });
    // });


    // $('.fode_loadmore').click(function(){

    //   var button = $(this),
    //       data = {
    //     'action': 'loadmore',
    //     'query': fode_loadmore_params.posts, // that's how we get params from wp_localize_script() function
    //     'page' : fode_loadmore_params.current_page
    //   };

    //   $.ajax({
    //     url : fode_loadmore_params.ajaxurl, // AJAX handler
    //     data : data,
    //     type : 'POST',
    //     beforeSend : function ( xhr ) {
    //       button.text('Loading...'); // change the button text, you can also add a preloader image
    //     },
    //     success : function( data ){
    //       if( data ) { 
    //         button.text( 'Load More' ).prev().before(data); // insert new posts
    //         fode_loadmore_params.current_page++;

    //         if ( fode_loadmore_params.current_page == fode_loadmore_params.max_page ) 
    //           button.remove(); // if last page, remove the button

    //         // you can also fire the "post-load" event here if you use a plugin that requires it
    //         // $( document.body ).trigger( 'post-load' );
    //       } else {
    //         button.remove(); // if no data, remove the button as well
    //       }
    //     }
    //   });
    // });


      // color change accoring to slide
      // var selectBody = $( 'body' );
      // var curId = 0;
      // selectBody.addClass( 'slide-' + curId );

      // $( '#carousel-example-generic' ).on( 'slid.bs.carousel', function () {
      //     var newId = $( this ).find( 'li.active' ).attr( 'data-slide-to' ); 
      //     if ( newId != curId ) {
      //       selectBody.removeClass( 'slide-' + curId ).addClass( 'slide-' + newId );
      //       curId = newId;
      //     }
      // });

      // widget style
      // $('.widget.widget_categories ul li a, .widget.widget_archive ul li a').append('<i class="fa fa-angle-right pull-right"></i>');
      // $('.fodeblog .widget.widget_categories ul li a i, .fodeblog .widget.widget_archive ul li a i').remove();
      // $('.widget.widget_categories ul li a, .widget.widget_archive ul li a').removeAttr('title');
      // $('.widget.widget_categories ul li a, .widget.widget_archive ul li a').hover(
      //    function(){ $(this).next().addClass('hover') },
      //    function(){ $(this).next().removeClass('hover') }
      // )

      // masonry blog post - resource: http://www.wpbeginner.com/wp-themes/how-to-use-masonry-to-add-pinterest-style-post-grid-in-wordpress/
      // if ( $( "#masonry-main" ).length ) {
      //   var container = document.querySelector('#masonry-main'); 
      //   var msnry;  
      //   imagesLoaded( container, function() {
      //       msnry = new Masonry( container, {
      //           itemSelector: '.masonry-item'
      //       });
      //   });
      // }

      // mobile menu
      $('.mobile-inner .menu-accordion ul.sub-menu').prev().addClass('has-submenu');
      $('.mobile-inner .menu-accordion ul').removeClass('sub-menu').addClass('dropdown');

      // footer widget
      // $('.footer-widget.widget_nav_menu ul li a').prepend('<i class="fa fa-angle-right"></i>');

      // comment reply link
      // $('.comment-reply-link').addClass('base-color reply-button');



    /*** =====================================
    * Easy Menu
    * =====================================***/
	 
	  //   $.fn.menumaker = function(options) {
	  //       var cssmenu = $(this),
	  //           settings = $.extend({
	  //               format: "dropdown",
	  //               sticky: false
	  //           }, options);
	  //       return this.each(function() {
	  //           $(this).find(".button").on('click', function() {
	  //               $(this).toggleClass('menu-opened');
	  //               var mainmenu = $(this).next('ul');
	  //               if (mainmenu.hasClass('open')) {
	  //                   mainmenu.slideToggle().removeClass('open');
	  //               } else {
	  //                   mainmenu.slideToggle().addClass('open');
	  //                   if (settings.format === "dropdown") {
	  //                       mainmenu.find('ul').show();
	  //                   }
	  //               }
	  //           });
	  //           cssmenu.find('li ul').parent().addClass('has-sub');
	  //           var multiTg;
	  //           multiTg = function() {
	  //               cssmenu.find(".has-sub").prepend('<span class="submenu-button"></span>');
	  //               cssmenu.find('.submenu-button').on('click', function() {
	  //                   $(this).toggleClass('submenu-opened');
	  //                   if ($(this).siblings('ul').hasClass('open')) {
	  //                       $(this).siblings('ul').removeClass('open').slideToggle();
	  //                   } else {
	  //                       $(this).siblings('ul').addClass('open').slideToggle();
	  //                   }
	  //               });
	  //           };
	  //           if (settings.format === 'multitoggle') multiTg();
	  //           else cssmenu.addClass('dropdown');
	  //           if (settings.sticky === true) cssmenu.css('position', 'fixed');
	  //           var resizeFix;
	  //           resizeFix = function() {
	  //               var mediasize = 1000;
	  //               if ($(window).width() > mediasize) {
	  //                   cssmenu.find('ul').show();
	  //               }
	  //               if ($(window).width() <= mediasize) {
	  //                   cssmenu.find('ul').hide().removeClass('open');
	  //               }
	  //           };
	  //           resizeFix();
	  //           return $(window).on('resize', resizeFix);
	  //       });
	  //   };
	


	  // $("#easy-menu").menumaker({
    //     format: "multitoggle"
    // });
   
    /** =====================================
    *   Search Box
    * =====================================**/
   	// $('.search-box a').on('click', function(e) {
    //     e.preventDefault();
    //     $('.top-search-input-wrap').addClass('show');
   	// });
   	// $(".top-search-input-wrap .top-search-overlay, .top-search-input-wrap .close-icon").on('click', function(){
    //     $('.top-search-input-wrap').removeClass('show');
   	// });

    /*** =====================================
    * 	Mobile Menu
    * =====================================***/
  	$('.mobile-background-nav .has-submenu').on('click',function(e) {
  	  	e.preventDefault();
  	    var $this = $(this);
  	    if ($this.next().hasClass('menu-show')) {
  	        $this.next().removeClass('menu-show');
  	        $this.next().slideUp(350);
  	    } else {
  	        $this.parent().parent().find('li .dropdown').removeClass('menu-show');
  	        $this.parent().parent().find('li .dropdown').slideUp(350);
  	        $this.next().toggleClass('menu-show');
  	        $this.next().slideToggle(350);
  	    }
  	});
    $('.mobile-menu-close i, .body-overlay').on('click',function(){
         $('.mobile-background-nav').removeClass('in');
           $('.body-overlay').fadeOut();
    });

  	$('.mobile-logo-search-humbarger .humbarger-button i').on('click',function(){
  	     $('.mobile-background-nav').toggleClass('in');
  	});
 

    $('.humbarger-icon a').on('click',function(e){
          e.preventDefault();
         $('.mobile-background-nav').toggleClass('in');
           $('.body-overlay').fadeIn();
    });


    /*** =====================================
    * Lazy load
    * ==================================== ***/
  	// $(window).on('load', function(){
    //       /** ===== Preloder ========**/
    //       $(".loading-overlay .spinner").fadeOut(300),
    //           $(".loading-overlay").fadeOut(300);
    //           $("body").css({
    //               overflow: "auto",
    //               height: "auto",
    //               position: "relative"
    //           })
  	// });

   

    /**=====================================
    *  Responsive Video
    * =====================================*/
    // if($('.fidvits-active').length){
    //     $(".fidvits-active").fitVids();
    // } 
     
        
    /* ------------------------------------------------------------------------------------------------------
    **  fode home slider
    ---------------------------------------------------------------------------------------------------------*/
    // var sliderSpeed = fode_loadmore_params.slide_speed;
    // $('.carousel').carousel({
    //   interval: sliderSpeed, 
    // });
    // $('.carousel-control.right').trigger('click');
    // $('.carousel-control.left').trigger('click');
    // //Function to animate slider captions
    // function doAnimations( elems ) {
    //   //Cache the animationend event in a variable
    //   var animEndEv = 'webkitAnimationEnd animationend';
    //   elems.each(function () {
    //     var $this = $(this),
    //     $animationType = $this.data('animation');
    //     $this.addClass($animationType).one(animEndEv, function () {
    //       $this.removeClass($animationType);
    //     });
    //   });
    // }
    // //Variables on page load
    // var $myCarousel = $('#carousel-example-generic'),
    // $firstAnimatingElems = $myCarousel.find('.item:first').find("[data-animation ^= 'animated']");
    // //Initialize carousel
    // $myCarousel.carousel();
    // //Animate captions in first slide on page load
    // doAnimations($firstAnimatingElems);
    // //Pause carousel
    // //$myCarousel.carousel('pause');
    // //Other slides to be animated on carousel slide event
    // $myCarousel.on('slide.bs.carousel', function (e) {
    // var $animatingElems = $(e.relatedTarget).find("[data-animation ^= 'animated']");
    // doAnimations($animatingElems);
    // });

 



    /*** =====================================
    * Mixit Up
    * =====================================***/ 
    // $('#mixitup-grid').mixItUp();
    // $('.filter-options ul li:first-child a').addClass("active"); 

    // $('#mixitup-grid').mixItUp({
    //   activeClass: 'on',
    //   callbacks: { 
    //       onMixEnd: function(state){
    //           masonryGrid(); // ******* here call masonry function
    //       }
    //   }
    // });

    // function masonryGrid(){
    //   var $container = $('#mixitup-grid');
    //   // initialize
    //   $container.masonry({
    //       itemSelector: '.mix',
    //       columnWidth: '.mix', //as you wish , you can use numeric 
    //       isAnimated: true,
    //   });
    //   $container.masonry('reloadItems');
    //   $container.masonry('layout');
    // }



    /*** =====================================
    * Portfolio Filter
    * ==================================== ***/
   // init Isotope
  //  var $grid = $('.grid').isotope({
  //     itemSelector: '.el-item',
  //     layoutMode: 'fitRows',
  //     getSortData: {
  //       title: '.title',
  //       time: '.time',
  //       id: '.id',
  //       order: '.order',
  //       category: '[data-category]',
  //       weight: function( itemElem ) {
  //         var weight = $( itemElem ).find('.weight').text();
  //         return parseFloat( weight.replace( /[\(\)]/g, '') );
  //       }
  //     }
  //   });

    // $(window).on('load', function(){
    //     var $grid = $('.isotope-grid').isotope({
    //         itemSelector: '.grid-item',
    //         percentPosition: true,
    //         masonry: {
    //             columnWidth: '.grid-item',
    //         }
    //     });
    // });




   




    /*** =====================================
    * One Page Scroll
    * ==================================== ***/
      // if ( $( ".one-page-main" ).length ) {
      //   $(".one-page-main").onepage_scroll({
      //       sectionContainer: ".section",
      //       responsiveFallback: 1199,
      //         easing: "ease",
      //       loop: true,
      //       direction: "vertical"

      //   });
      // }

    /** =====================================
      *  Portfolio Image Popup
      * ===================================== **/
      // if ( $( ".magnific-all" ).length ) {
      //    $('.magnific-all').each(function() {
      //        var $container = $(this);
      //        var $imageLinks = $container.find('.item');

      //        var items = [];
      //        $imageLinks.each(function() {
      //            var $item = $(this);
      //            var type = 'image';
      //            if ($item.hasClass('magnific-youtube')) {
      //                type = 'iframe';
      //            }
      //            var magItem = {
      //                src: $item.attr('href'),
      //                type: type
      //            };
      //            magItem.title = $item.data('title');
      //            items.push(magItem);
      //        });

      //        $imageLinks.magnificPopup({
      //            mainClass: 'mfp-fade',
      //            items: items,
      //            gallery: {
      //                enabled: true,
      //                tPrev: $(this).data('prev-text'),
      //                tNext: $(this).data('next-text')
      //            },
      //            type: 'image',
      //            removalDelay: 500,
      //            callbacks: {
      //                beforeOpen: function() {
      //                    this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
      //                    this.st.mainClass = this.st.el.attr('data-effect');
      //                    var index = $imageLinks.index(this.st.el);
      //                    if (-1 !== index) {
      //                        this.goTo(index);
      //                    }
      //                }
      //            },
      //        });
      //    });
      // }


    /*** =====================================
    * Progress
    * ==================================== ***/
    // jQuery(window).on('scroll', function() {
    //     var windowHeight = $(window).height();
    //     function kalProgress() {
    //        var progress = $('.progress-rate');
    //        var len = progress.length;
    //        for (var i = 0; i < len; i++) {
    //            var progressId = '#' + progress[i].id;
    //            var dataValue = $(progressId).attr('data-value');
    //            $(progressId).css({'width':dataValue+'%'});
    //        }
    //     }
    //     var progressRateClass = $('#progress-running');
    //      if ((progressRateClass).length) {
    //          var progressOffset = $("#progress-running").offset().top - windowHeight;
    //          if ($(window).scrollTop() > progressOffset) {
    //              kalProgress();
    //          }
    //      }
    //  });


  });
}(jQuery));