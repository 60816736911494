require("./chunk/_polyfills.js");

import { debounce } from "./chunk/_utils.js";

require("jquery");
// require("./lib/bootstrap.min.js");
import "./custom.js";
import "./chunk/shuffle.js";






/**
 * Video inline
 */
import scroll_video from "./chunk/scroll_video";

let scrollspyHandler = (state) => {
  // const isBigScreen = window.innerWidth > 992 ? true : false;
  scroll_video(state);
};

/**
 * throtteld restart of scriptStateHandler()
 * on viewport changes
 */
let debounceAnimationScripts = debounce(
  function (state = true) {
    scrollspyHandler();
  },
  300,
  false
);

window.addEventListener("resize", debounceAnimationScripts);
window.addEventListener("orientationchange", debounceAnimationScripts);

if (document.readyState == "complete") {
  scrollspyHandler();
} else {
  document.onreadystatechange = function () {
    scrollspyHandler();
  };
}




/**
 * Video YouTube
 */
// https://appleple.github.io/modal-video/
require("./lib/jquery-modal-video.min.js");


$(".js-modal-btn").modalVideo({
  youtube:{
    controls: 1,
    nocookie: true,
    autoplay: 1,
    modestbranding: 1,
    playsinline: 0, // 0 -> plays in fullscreen on iOS, 1 -> plays in HTML
  }
});







/** 
 * Flickity 
 */
import Flickity from "./lib/flickity.pkgd.min.js";
var carousel = document.querySelector(".carousel");
if (carousel) {
  var flkty = new Flickity(carousel, {
    // options
    // cellAlign: 'left',
    imagesLoaded: true,
    percentPosition: false,
    setGallerySize: false,
    prevNextButtons: false,
    wrapAround: true, // infinite
  
    autoPlay: 5000,
    pauseAutoPlayOnHover: false,
  });
}
